import { genericPage, getMappingProgramaSlug, getServerSidePropsHelper, isCr, isSx3 } from 'components/3cat/Page';
import __isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import { dataResources } from 'utils/constants';

// /nom-bonic-programa/

const Pagina = (props) => {
    const router = useRouter();
    return genericPage(props, router, ['web', process.env.FOLDER_LAYOUTS, 'nom-programa']); //es necessita passar el profile
};

export async function getServerSideProps(context) {
    const { slug } = context.query;

    let path = ['web', process.env.FOLDER_LAYOUTS, slug];

    const mapping = await getMappingProgramaSlug(slug);

    if (!__isEmpty(mapping)) {
        if (mapping.lay && isSx3(mapping)) {
            path = ['web', process.env.FOLDER_LAYOUTS, 'programa', 'emissio-clips'];
        } else if (mapping.lay && isCr(mapping)) {
            if (mapping.lay.includes(dataResources.homeWPTemplate)) {
                // Plantilla
                path = [
                    'web',
                    process.env.FOLDER_LAYOUTS,
                    'programa',
                    mapping.lay.replace('catradio' + dataResources.homeWPTemplate, '')
                ];
            } else if (mapping.lay.includes(dataResources.homeWPCustom)) {
                // Custom
                path = ['web', process.env.FOLDER_LAYOUTS, 'programa', 'custom-radio', '' + mapping.id];
            } else {
                // mapping.lay === 'catradio_item_fitxa-programa' // Generica
                path = ['web', process.env.FOLDER_LAYOUTS, 'programa', 'radio'];
            }
        } else {
            // TV3
            if (mapping.lay && mapping.lay.includes(dataResources.homeWPTemplate)) {
                // Plantilla
                path = [
                    'web',
                    process.env.FOLDER_LAYOUTS,
                    'programa',
                    mapping.lay.replace('tv3' + dataResources.homeWPTemplate, '')
                ];
            } else if (mapping.lay && mapping.lay.includes(dataResources.homeWPCustom)) {
                // Custom
                path = ['web', process.env.FOLDER_LAYOUTS, 'programa', 'custom', '' + mapping.id];
            } else {
                // mapping.lay === 'tv3_item_fitxa-programa' // Generica
                path = ['web', process.env.FOLDER_LAYOUTS, 'programa'];
            }
        }
    }

    const options = {
        slugs: path,
        id: mapping.id
    };

    return getServerSidePropsHelper(context, options);
}

export default Pagina;
